import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { StaticImage } from "gatsby-plugin-image";

class NewsTabs extends Component {
    render() {
        let tab1 = "Winter Holidays";

        const { tabStyle } = this.props;

        return (
            <div className="tabs-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs>
                                <TabList className={`${tabStyle}`}>
                                    <Tab>{tab1}</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="single-tab-content mt-4">
                                        <div className="row">
                                            <div className="col-md-8 pr-5">
                                                <h3>
                                                    Winter Holidays 2023
                                                </h3>

                                                <p className="mb-15px">
                                                    During the winter holidays, the gnome of Sitarjev invites you to the mine. Scheduled appointments for this weekend are from <strong>Friday to Sunday (February 10 - February 12) at 11:00 a.m. and 3:00 p.m</strong>.
                                                </p>

                                                <p className="mb-15px">
                                                    Advance notice required:<strong> +38651 312 739, +38670 700 484 ali info@visitlitija.si. </strong>.
                                                </p>
                                            </div>

                                            <div className="col-md-4 flex justify-content-start align-items-start mt-3">
                                                <div className="thumb hover-slide-up">
                                                    <StaticImage
                                                        src="../../assets/images/rudnik/s-skratom-po-rudniku.jpeg"
                                                        alt="S Škratom po rudniku Sitarjevec"
                                                        placeholder="blurred"
                                                        imgClassName="img-fluid w-100"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default NewsTabs;
