import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { StaticImage } from "gatsby-plugin-image";

function SliderHome(props) {
    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(
                    relativePath: { eq: "rudnik/rudnik-sitarjevec.jpeg" }
                ) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const imageData = data.desktop.childImageSharp.fluid;

    const SlideList = [
        {
            textPosition: "text-center",
            title: "Sitarjevec Litija Mine",
            description: "Experience the richness of minerals and the stories of the miners.",
        },
    ];

    function scrollToFirstBlock(e) {
        e.preventDefault();
        let element = document.getElementById("mine-description");

        // smooth scroll to element and align it at the bottom
        element.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    function scrollToNews(e){
        e.preventDefault();

        let element = document.getElementById("news");

        element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return (
        <div>
            <div className="slider-activation im_modern_slider">
                <div className="bg-black-drop-overlay" />
                <BackgroundImage
                    Tag="div"
                    className="bg_image"
                    fluid={imageData}
                    backgroundColor={`#040e18`}
                >
                    {SlideList.map((value, index) => (
                        <div
                            className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                            key={index}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div
                                            className={`inner ${value.textPosition}`}
                                        >
                                            <StaticImage
                                                src="../../assets/images/rudnik/logo-brez.png"
                                                alt="White logo of Sitarjevec Mine"
                                                placeholder="blurred"
                                                imgClassName="img-fluid mb-4 p-3"
                                                loading="lazy"
                                            />
                                            <h1 className="title mb-0">
                                                {value.title}
                                            </h1>
                                            {value.description ? (
                                                <p className="description">
                                                    {value.description}
                                                </p>
                                            ) : (
                                                ""
                                            )}

                                            <div className="mt-5 d-flex flex-column text-center justify-content-center align-items-center" >
                                                <div className="badge d-inline-block pulse">NEW!</div>
                                                <div className="d-inline-block font--18 text-white mt-2" style={{maxWidth: "420px"}}>It is possible to visit the Export Pit of the mine without prior notice <strong>every Saturday at 10:00 AM.</strong></div>
                                            </div>

                                            {/*<div className="slide-btn">*/}
                                            {/*    <Link*/}
                                            {/*        to="/en/visit-the-mine/the-main-pit"*/}
                                            {/*        className="btn-default uppercase"*/}
                                            {/*    >*/}
                                            {/*         Visit the mine*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}

                                            {/*<div className="slide-btn">*/}
                                            {/*    <Link*/}
                                            {/*        className="btn-default"*/}
                                            {/*        to={"/obisci-rudnik/glavni-rov"}*/}
                                            {/*        onClick={scrollToNews}*/}
                                            {/*    >*/}
                                            {/*        Winter Holidays*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}

                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="scroll-down-signal"
                                    onClick={scrollToFirstBlock}
                                >
                                    <a href="">
                                        <span />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </BackgroundImage>
            </div>

        </div>
    );
}

export default SliderHome;
