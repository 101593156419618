import React from 'react';
import NewsTabs from "./NewsTabs";

function News(props) {
    return (
        <div className="portfolio-related-work ptb--120" id="news">
            <div className="about-wrapper p-4 p-md-0">
                <div className="container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <span className="primary-subtitle">Announcements</span>
                                    <h2 className="title">Current News From the Underground</h2>
                                </div>
                                <div className="row mt--30">
                                    <NewsTabs tabStyle="tab-style--1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;